import PapercutApi, { PAPERCUT_API_URL } from '@/api/base/PapercutApi'

export default {
  createOrders(orders, params) {
    return PapercutApi.post(`${PAPERCUT_API_URL}/orders`, { ...params, orders })
  },
  updateOrderReferredBy(orderId, referredBy) {
    return PapercutApi.patch(`${PAPERCUT_API_URL}/orders/${orderId}/referred_by`, { referredBy })
  },
  initialPromoCodeValidation(promoCode, orders, customer, total) {
    return PapercutApi.post(`${PAPERCUT_API_URL}/orders/initial_promo_code_validation`, { promoCode, orders, customer, total })
  }
}
