<template>
  <div class="BasePage">
    <shout :shoutType="shoutType"/>
    <div
      v-if="$slots.header"
      class="PageHeader">
      <slot name="header"/>
    </div>
    <div class="PageBody">
      <slot/>
    </div>
    <div
      v-if="$slots.footer"
      class="PageFooter">
      <slot name="footer"/>
    </div>
    <common-modal/>
    <closed-hours-data/>
  </div>
</template>

<script>
const Shout = () => import('@/components/common/Shout')
const CommonModal = () => import('@/components/common/CommonModal')
const ClosedHoursData = () => import('@/components/ClosedHoursData')

export default {
  name: 'base-page',
  components: {
    Shout,
    CommonModal,
    ClosedHoursData
  },
  props: {
    shoutType: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.BasePage {
  color: $ink;
  text-align: left;
}

.PageBody {
  margin-bottom: $space-xxl;
}
</style>
