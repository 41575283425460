<template>
  <base-page
    shoutType="catering"
    class="SuccessPage">
    <div class="Container">
      <div class="SuccessHeader">
        <img
          src="~@/assets/images/icons/icon-success.svg"
          class="SuccessIcon">
        <base-header>
          <span
            slot="title"
            v-html="$t(`success.${orderPaid ? 'paidTitle' : 'title'}`)"/>
          <span
            slot="byline"
            v-html="$t(`success.${orderPaid ? 'paidByline': 'byline'}`, { phoneLink: `<a class='Button--text' href='tel:+6531635336'><strong>+65 3163 5336</strong></a>`, emailLink: `<a class='Button--text' href='mailto:catering@grain.com.sg'>catering@grain.com.sg</a>` })"/>
        </base-header>
        <div class="SuccessHeaderActions">
          <template v-if="orderPaid">
            <a
              class="Button Button--primary"
              :href="orderPdfUrl"
              target="_blank">
              {{ $t(`success.${orderPaid ? 'viewConfirmationTitle' : 'viewQuotationTitle'}`) }}
            </a>
            <div
              class="Button Button--ghost"
              @click="goHome">
              {{ $t('success.backToHomeButtonTitle') }}
            </div>
          </template>
          <template v-else>
            <div
              class="Button Button--primary"
              @click="goHome">
              {{ $t('success.backToHomeButtonTitle') }}
            </div>
          </template>
        </div>
      </div>
      <template v-if="false">
        <div class="SuccessBody">
          <div class="Actions">
            <div class="Action">
              <img
                src="~@/assets/images/icons/icon-print.svg"
                class="ActionIcon">
              <span class="ActionLabel">
                Print
              </span>
            </div>
            <div class="Action">
              <img
                src="~@/assets/images/icons/icon-mail.svg"
                class="ActionIcon">
              <span class="ActionLabel">
                Send email
              </span>
            </div>
          </div>
          <div class="OrderConfirmation">
            <h3 class="OrderConfirmationTitle">
              Order confirmation
            </h3>
          </div>
        </div>
      </template>
    </div>
    <template v-if="referralSelectorModalVisible">
      <referral-selector-modal :salesOrder="salesOrder"/>
    </template>
  </base-page>
</template>

<script>
import { RESET_FULFILMENT_STATE, RESET_MENU_STATE, RESET_ORDER_STATE } from '../actions'
import BaseHeader from '../components/common/BaseHeader/BaseHeader'
import BasePage from '../components/common/BasePage/BasePage'
import ReferralSelectorModal from '../components/ReferralSelectorModal'
import cookies from '@/assets/js/cookies'
import { mapGetters } from 'vuex'

export default {
  name: 'success-page',
  metaInfo: {
    title: 'Grain | Food experience company in Singapore',
    meta: [
      { name: 'robots', content: 'none' }
    ]
  },
  components: {
    BasePage,
    BaseHeader,
    ReferralSelectorModal
  },
  data() {
    return {
      createAccount: false,
      customerEmail: null,
      salesOrder: null,
      orderPaid: false,
      referredBy: null
    }
  },
  computed: {
    ...mapGetters([
      'getCheckoutComplete',
      'getSalesOrder',
      'getOrderPaid',
      'getCreateAccount',
      'getCustomer'
    ]),
    orderId() {
      if (this.salesOrder) {
        return this.salesOrder.orderId
      }
      return ''
    },
    orderPdfUrl() {
      return `${process.env.VUE_APP_SPINE_URL}/catering_orders/${this.orderId}`
    },
    referralSelectorModalVisible() {
      return this.salesOrder && !this.referredBy
    }
  },
  created() {
    if (this.getCheckoutComplete) {
      this.createAccount = this.getCreateAccount
      this.customerEmail = this.getCustomer.email
      this.salesOrder = this.getSalesOrder
      this.orderPaid = this.getOrderPaid
      this.referredBy = cookies.getCookie('referred_by')

      this.$store.dispatch(RESET_MENU_STATE)
      this.$store.dispatch(RESET_ORDER_STATE)
      this.$store.dispatch(RESET_FULFILMENT_STATE)
      cookies.deleteCookie('referred_by')
    }
  },
  mounted() {
    if (this.referralSelectorModalVisible) {
      this.$modal.show('referral-selector-modal')
    }
  },
  methods: {
    goHome() {
      this.$router.push('/catering')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
.SuccessPage {
  /deep/ .PageBody {
    margin-bottom: 0;

    > .Container {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .SuccessHeader {
    padding: 4.5rem 0;

    .SuccessIcon {
      display: block;
      width: 48px;
      margin: 0 auto 1rem;
    }
    /deep/ {
      .HeaderTitle {
        margin-top: 0;
      }
      .NoWrap {
        white-space: nowrap;
      }
    }
    .SuccessHeaderActions {
      .Button + .Button {
        margin-left: 1rem;
      }
    }
  }
  .SuccessBody {
    .Actions {
      text-align: right;
      margin-bottom: 0.5rem;

      .Action {
        display: inline-block;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 1.5rem;
        }
        .ActionIcon {
          vertical-align: middle;
          margin-right: 0.25rem;
        }
        .ActionLabel {
          font-size: 8px;
          font-weight: bold;
          color: $gray-darker;
        }
      }
    }
    .OrderConfirmation {
      padding: 2.75rem 2.5rem;
      background-color: $white;
      text-align: left;

      .OrderConfirmationTitle {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
</style>
