<template>
  <modal
    :name="name"
    overlayTransition=""
    :clickToClose="submitted"
    class="Modal ReferralSelectorModal"
    :class="{'Submitted': submitted}">
    <span
      class="ModalClose"
      @click="closeModal">
      ✕
    </span>
    <h3 v-html="$t('referralSelectorModal.title')"/>
    <template v-if="loading">
      {{ $t('common.loading') }}
    </template>
    <template v-else-if="submitted">
      {{ $t('referralSelectorModal.thankYouNote') }}
    </template>
    <template v-else>
      <referral-selector-option
        v-model="selectedSalesRepresentative"
        :salesRepresentative="null"
        :selectedSalesRepresentative="selectedSalesRepresentative"
        class="NoOne"/>
      <div class="OrBreak">
        <p>
          OR
        </p>
      </div>
      <template v-for="salesRepresentative in salesRepresentatives">
        <referral-selector-option
          :key="`sales_representative_${salesRepresentative.name}`"
          v-model="selectedSalesRepresentative"
          :salesRepresentative="salesRepresentative"
          :selectedSalesRepresentative="selectedSalesRepresentative"/>
      </template>
      <div class="ModalActions">
        <a
          class="Button Button--primary"
          :class="{'disabled': submitting}"
          @click="updateReferredBy">
          {{ $t(`referralSelectorModal.${submitting ? 'submitting' : 'submit'}`) }}
        </a>
      </div>
    </template>
  </modal>
</template>

<script>
import OrdersApi from '@/api/OrdersApi'
import SalesRepresentativesApi from '@/api/SalesRepresentativesApi'

const ReferralSelectorOption = () => import('./ReferralSelectorOption')

export default {
  name: 'referral-selector-modal',
  components: {
    ReferralSelectorOption
  },
  props: {
    salesOrder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: 'referral-selector-modal',
      loading: false,
      salesRepresentatives: [],
      selectedSalesRepresentative: null,
      submitting: false,
      submitted: false
    }
  },
  created() {
    this.fetchSalesRepresentatives()
  },
  methods: {
    closeModal() {
      this.$modal.hide(this.name)
    },
    async fetchSalesRepresentatives() {
      this.loading = true
      const result = (await SalesRepresentativesApi.index())
      this.salesRepresentatives = result.salesRepresentatives
      this.loading = false
    },
    async updateReferredBy() {
      if (this.selectedSalesRepresentative) {
        this.submitting = true
        const result = (await OrdersApi.updateOrderReferredBy(this.salesOrder.orderId, this.selectedSalesRepresentative.email))
        if (!result.error) {
          this.submitted = true
        }
        this.submitting = false
      } else {
        this.submitted = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.ReferralSelectorModal {
  &.Submitted /deep/ .v--modal {
    text-align: center;
  }
  /deep/ .v--modal {
    @extend %scrollbar;
  }
  h3 {
    @extend %display_medium;
    font-weight: bold;
    text-align: center;
    margin-bottom: $space-l;
  }
  .NoOne {
    position: relative;
    margin-bottom: 0;
    width: 100%;
  }
  .ModalActions {
    text-align: center;

    .Button {
      margin-top: $space-l;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
</style>
