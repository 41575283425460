<template>
  <div class="BaseHeader">
    <h2
      v-if="$slots.title || title"
      class="HeaderTitle">
      <slot name="title">
        <span v-html="title"/>
      </slot>
    </h2>
    <h4
      v-if="$slots.byline || byline"
      class="HeaderByline">
      <slot name="byline">
        <span v-html="byline"/>
      </slot>
    </h4>
  </div>
</template>

<script>
export default {
  name: 'base-header',
  props: {
    title: {
      type: String,
      default: null
    },
    byline: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_shared_variables.sass";

.BaseHeader {
  text-align: center;

  .HeaderTitle {
    margin: $space-xl 0 $space-xs;
    font-weight: bold;
  }
  .HeaderByline {
    margin-bottom: $space-l;
  }
}
</style>
